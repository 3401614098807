<template>
    <CRow>
      <CCol sm="12">
        <CDataTable
            :items="guests"
            :fields="fields"
            :noItemsView="{noItems:$t('Нет данных')}"
            striped
        >
        </CDataTable>
        <div v-if="this.pages > 1">
          <router-link
              :to="'/calendar/stat/' + $route.params.cid + '/' + $route.params.type + '/' + index"
              v-for="index in this.pages"
              :key="index"
              :class="(parseInt($route.params.page) === parseInt(index)) ? 'current_page page' : 'page'"
          >
            {{index}}
          </router-link>
        </div>
      </CCol>
    </CRow>
</template>

<script>
import axios from '@/modules/axios'

export default {
  name: 'Calendars',
  data () {
    return {
      guests: [],
      pages: 0,
      fields: [
        {key: 'timezone', label: this.$t('Часовой пояс'), sortable: false},
        {key: 'client_user_id', label: 'uid', sortable: false},
        {key: 'device', label: 'Девайс', sortable: false},
      ]
    }
  },
  created() {
    this.getGuests()
  },
  methods: {
    getGuests () {
      axios.get('/guest/list', {
        params: {calendar_id: this.$route.params.cid, type: this.$route.params.type, page: this.$route.params.page}
      }).then((res) => {
        this.guests = res.data.data.guests;
        this.pages = res.data.data.pages;
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    }
  }
}
</script>
<style lang="scss">
.page {
  color: grey;
  border: 1px solid grey;
  display: inline-block;
  padding: 3px 10px;
  margin-right: 7px;
}
.page:hover {
  color: grey;
  text-decoration: none;
}
.current_page {
  background: grey;
  color: white;
}
.current_page:hover {
  color: white;
}
</style>
